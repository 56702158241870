import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../@core/auth/auth.service';
import { ScaleBatchDTO, ScaleConfigurationDTO } from '../models/scales';


@Injectable({
  providedIn: 'root'
})
export class ScaleService {

  public selectedBatch: ScaleBatchDTO;

  basePath: string;
  configUrl: string;
  batchUrl: string;
  dataUrl: string;

  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  
    this.configUrl = this.basePath + '/api/scale/configurations';
    this.batchUrl = this.basePath + '/api/scale/batches';
    this.dataUrl = this.basePath + '/api/scale/data';
  }


  // BATCHES

  public getBatches(runningOnly: boolean = false): Observable<ScaleBatchDTO[]> {

    let queryParameters = new HttpParams();



    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<ScaleBatchDTO[]>(`${this.batchUrl}`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  
  }

  public getBatch(batchId: number): Observable<ScaleBatchDTO> {

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append('batchId', batchId.toString());


    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<ScaleBatchDTO>(`${this.batchUrl}/${batchId}`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  
  }


  public updateBatch(batch: ScaleBatchDTO): Observable<ScaleBatchDTO> {

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append('batchId', batch.id.toString());


    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.put<ScaleBatchDTO>(`${this.batchUrl}/${batch.id}`, batch,
      {
        params: queryParameters,
        headers: headers,
      }
    )
  }

  public getScaleConfig(): Observable<ScaleConfigurationDTO[]> {

    let queryParameters = new HttpParams();

    // queryParameters = queryParameters.append('batchId', batchId.toString());


    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<ScaleConfigurationDTO[]>(`${this.configUrl}`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  
  }


}
